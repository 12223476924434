.parent-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.custom-shape-divider-top-1631949294 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: -1;
}

.custom-shape-divider-top-1631949294 svg {
    position: relative;
    display: block;
    width: calc(143% + 1.3px);
    height: 211px;
}

.custom-shape-divider-top-1631949294 .shape-fill {
    fill: #ffc107;
}


/**/

.custom-shape-divider-bottom-1631949675 {
    position: absolute;
    bottom: 56px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: -1;
}

.custom-shape-divider-bottom-1631949675 svg {
    position: relative;
    display: block;
    width: calc(157% + 1.3px);
    height: 281px;
}

.custom-shape-divider-bottom-1631949675 .shape-fill {
    fill: #FFC107;
}


/* @media screen and (min-width:960px) and (max-width:1023px) {
} */

@media screen and (max-width:1023px) {}