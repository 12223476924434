.custom-shape-divider-bottom-1631943458 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1631943458 svg {
    position: relative;
    display: block;
    width: calc(204% + 1.3px);
    height: 141px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1631943458 .shape-fill {
    fill: #FFC107;
}

.custom-shape-divider-top-1631944311 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1631944311 svg {
    position: relative;
    display: block;
    width: calc(204% + 1.3px);
    height: 141px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1631944311 .shape-fill {
    fill: #FFC107;
}

.error-wrapper {
    position: relative;
    min-height: 100%;
    overflow: hidden;
    background-color: #faa029;
}

.page-not-found-error {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-top: calc(50vh);
}

.page-not-found-heading {
    color: #ffffff;
}

.page-not-found-body {
    color: #ffffff;
}

a {
    color: #fff;
    text-decoration: none;
}