.showcase {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.showcaseContainer {
    padding: 1rem;
}

.scroll-div {
    border-radius: 5px 5px 5px 5px;
    /* float: left; */
    height: 400px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    /* width: 100%; */
    border-top: 30px solid #adadac;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.scrollableImage {
    position: absolute;
    transition: all 0.2s ease 0s;
    width: 100%;
}

.scroll-div:hover>img {
    animation: animationFrames linear 10s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: animationFrames linear 10s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationFrames linear 10s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationFrames linear 10s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationFrames linear 10s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
}

@keyframes animationFrames {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(0px, calc(-50%));
    }
    100% {
        transform: translate(0px, calc(-100% + 400px));
    }
}

@-moz-keyframes animationFrames {
    0% {
        -moz-transform: translate(0px, 0px);
    }
    50% {
        -moz-transform: translate(0px, calc(-50%));
    }
    100% {
        -moz-transform: translate(0px, calc(-100% + 400px));
    }
}

@-webkit-keyframes animationFrames {
    0% {
        -webkit-transform: translate(0px, 0px);
    }
    50% {
        -webkit-transform: translate(0px, calc(-50%));
    }
    100% {
        -webkit-transform: translate(0px, calc(-100% + 400px));
    }
}

@-o-keyframes animationFrames {
    0% {
        -o-transform: translate(0px, 0px);
    }
    50% {
        -o-transform: translate(0px, calc(-50%));
    }
    100% {
        -o-transform: translate(0px, calc(-100% + 400px));
    }
}

@-ms-keyframes animationFrames {
    0% {
        -ms-transform: translate(0px, 0px);
    }
    50% {
        -ms-transform: translate(0px, calc(-50%));
    }
    100% {
        trans-ms-transformform: translate(0px, calc(-100% + 400px));
    }
}

.showcase-paper {
    padding: 2rem;
    margin: 2rem;
}

.showcase-inner-paper {
    margin: 1rem 0 1rem 0;
}

@media screen and (min-width:0px) and (max-width:600px) {
    .showcase-paper {
        padding: 0.5rem;
        margin: 0.5rem;
    }
    .showcase-inner-paper {
        font-size: 9px;
    }
    .scroll-div {
        height: 200px;
        border-top: 4px solid #adadac;
    }
}

@media screen and (min-width:601px) and (max-width:960px) {
    .showcase-paper {
        padding: 0.5rem;
        margin: 0.5rem;
    }
    .showcase-inner-paper {
        font-size: 10px;
    }
    .scroll-div {
        height: 300px;
        border-top: 10px solid #adadac;
    }
}