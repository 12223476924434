.dark-primary-color {
    background: #FFA000;
}

.default-primary-color {
    background: #FFC107;
}

.light-primary-color {
    background: #FFECB3;
}

.text-primary-color {
    color: #212121;
}

.accent-color {
    background: #795548;
}

.primary-text-color {
    color: #212121;
}

.secondary-text-color {
    color: #757575;
}

.divider-color {
    border-color: #BDBDBD;
}